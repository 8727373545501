/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';


@Injectable({ providedIn: 'root' })
export class HelpersService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `homainsDevopsHelpersCount()` */
  static readonly HomainsDevopsHelpersCountPath = '/api/method/homains_devops.helpers.count';

  /**
   * Invoke the homains_devops.helpers.count method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `homainsDevopsHelpersCount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  homainsDevopsHelpersCount$Response(
    params: {

    /**
     * Resource Name
     */
      doctype: string;

    /**
     * Filters
     */
      filters: string;

    /**
     * Or Filters
     */
      or_filters: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, HelpersService.HomainsDevopsHelpersCountPath, 'post');
    if (params) {
      rb.query('doctype', params.doctype, {});
      rb.query('filters', params.filters, {});
      rb.query('or_filters', params.or_filters, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the homains_devops.helpers.count method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `homainsDevopsHelpersCount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  homainsDevopsHelpersCount(
    params: {

    /**
     * Resource Name
     */
      doctype: string;

    /**
     * Filters
     */
      filters: string;

    /**
     * Or Filters
     */
      or_filters: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.homainsDevopsHelpersCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `homainsDevopsHelpersGet()` */
  static readonly HomainsDevopsHelpersGetPath = '/api/method/homains_devops.helpers.get';

  /**
   * Invoke the homains_devops.helpers.get method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `homainsDevopsHelpersGet()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  homainsDevopsHelpersGet$Response(
    params: {

    /**
     * Resource Name
     */
      doctype: string;

    /**
     * Fields
     */
      fields: string;

    /**
     * status
     */
      group_by: string;

    /**
     * True
     */
      distinct: string;

    /**
     * Filters
     */
      filters: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, HelpersService.HomainsDevopsHelpersGetPath, 'post');
    if (params) {
      rb.query('doctype', params.doctype, {});
      rb.query('fields', params.fields, {});
      rb.query('group_by', params.group_by, {});
      rb.query('distinct', params.distinct, {});
      rb.query('filters', params.filters, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the homains_devops.helpers.get method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `homainsDevopsHelpersGet$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  homainsDevopsHelpersGet(
    params: {

    /**
     * Resource Name
     */
      doctype: string;

    /**
     * Fields
     */
      fields: string;

    /**
     * status
     */
      group_by: string;

    /**
     * True
     */
      distinct: string;

    /**
     * Filters
     */
      filters: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.homainsDevopsHelpersGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `homainsDevopsHelpersBulkUpdate()` */
  static readonly HomainsDevopsHelpersBulkUpdatePath = '/api/method/homains_devops.helpers.bulk_update';

  /**
   * Invoke the homains_devops.helpers.bulk_update method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `homainsDevopsHelpersBulkUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  homainsDevopsHelpersBulkUpdate$Response(
    params: {

    /**
     * Resource
     */
      resource: string;

    /**
     * Array of Names
     */
      names: string;

    /**
     * {KV}
     */
      data: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, HelpersService.HomainsDevopsHelpersBulkUpdatePath, 'post');
    if (params) {
      rb.query('resource', params.resource, {});
      rb.query('names', params.names, {});
      rb.query('data', params.data, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the homains_devops.helpers.bulk_update method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `homainsDevopsHelpersBulkUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  homainsDevopsHelpersBulkUpdate(
    params: {

    /**
     * Resource
     */
      resource: string;

    /**
     * Array of Names
     */
      names: string;

    /**
     * {KV}
     */
      data: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.homainsDevopsHelpersBulkUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `homainsDevopsHelpersVersions()` */
  static readonly HomainsDevopsHelpersVersionsPath = '/api/method/homains_devops.helpers.versions';

  /**
   * Invoke the homains_devops.helpers.versions method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `homainsDevopsHelpersVersions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  homainsDevopsHelpersVersions$Response(
    params?: {
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, HelpersService.HomainsDevopsHelpersVersionsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the homains_devops.helpers.versions method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `homainsDevopsHelpersVersions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  homainsDevopsHelpersVersions(
    params?: {
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.homainsDevopsHelpersVersions$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `homainsDevopsHelpersContactUs()` */
  static readonly HomainsDevopsHelpersContactUsPath = '/api/method/homains_devops.helpers.contact_us';

  /**
   * Invoke the homains_devops.helpers.contact_us method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `homainsDevopsHelpersContactUs()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  homainsDevopsHelpersContactUs$Response(
    params: {

    /**
     * Name
     */
      name: string;

    /**
     * Email
     */
      email: string;

    /**
     * Subject
     */
      subject: string;

    /**
     * Your Message
     */
      message: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, HelpersService.HomainsDevopsHelpersContactUsPath, 'post');
    if (params) {
      rb.query('name', params.name, {});
      rb.query('email', params.email, {});
      rb.query('subject', params.subject, {});
      rb.query('message', params.message, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the homains_devops.helpers.contact_us method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `homainsDevopsHelpersContactUs$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  homainsDevopsHelpersContactUs(
    params: {

    /**
     * Name
     */
      name: string;

    /**
     * Email
     */
      email: string;

    /**
     * Subject
     */
      subject: string;

    /**
     * Your Message
     */
      message: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.homainsDevopsHelpersContactUs$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
