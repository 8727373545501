/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { HmnzTelesalesCall } from '../models/hmnz-telesales-call';
import { TelesalesCallFilters } from '../models/telesales-call-filters';

@Injectable({ providedIn: 'root' })
export class TelesalesCallResourceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTelesalesCalls()` */
  static readonly GetTelesalesCallsPath = '/api/resource/TelesalesCall';

  /**
   * Get TelesalesCall resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTelesalesCalls()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTelesalesCalls$Response(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': Array<HmnzTelesalesCall>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, TelesalesCallResourceService.GetTelesalesCallsPath, 'get');
    if (params) {
      rb.query('filters', params.filters, {});
      rb.query('limit_page_length', params.limit_page_length, {});
      rb.query('limit_start', params.limit_start, {});
      rb.query('order_by', params.order_by, {});
      rb.query('or_filters', params.or_filters, {});
      rb.query('fields', params.fields, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': Array<HmnzTelesalesCall>;
        }>;
      })
    );
  }

  /**
   * Get TelesalesCall resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTelesalesCalls$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTelesalesCalls(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<{
'data': Array<HmnzTelesalesCall>;
}> {
    return this.getTelesalesCalls$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': Array<HmnzTelesalesCall>;
}>): {
'data': Array<HmnzTelesalesCall>;
} => r.body)
    );
  }

  /** Path part for operation `patchTelesalesCall()` */
  static readonly PatchTelesalesCallPath = '/api/resource/TelesalesCall';

  /**
   * Patch TelesalesCall resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchTelesalesCall()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTelesalesCall$Response(
    params: {
      filters?: TelesalesCallFilters;
      body: HmnzTelesalesCall
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzTelesalesCall>> {
    const rb = new RequestBuilder(this.rootUrl, TelesalesCallResourceService.PatchTelesalesCallPath, 'patch');
    if (params) {
      rb.query('filters', params.filters, {"style":"form"});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzTelesalesCall>;
      })
    );
  }

  /**
   * Patch TelesalesCall resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchTelesalesCall$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTelesalesCall(
    params: {
      filters?: TelesalesCallFilters;
      body: HmnzTelesalesCall
    },
    context?: HttpContext
  ): Observable<HmnzTelesalesCall> {
    return this.patchTelesalesCall$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzTelesalesCall>): HmnzTelesalesCall => r.body)
    );
  }

  /** Path part for operation `updateTelesalesCall()` */
  static readonly UpdateTelesalesCallPath = '/api/resource/TelesalesCall/{name}';

  /**
   * Update TelesalesCall resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTelesalesCall()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTelesalesCall$Response(
    params: {

    /**
     * The name of the TelesalesCall to update
     */
      name: string;
      body: HmnzTelesalesCall
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzTelesalesCall>> {
    const rb = new RequestBuilder(this.rootUrl, TelesalesCallResourceService.UpdateTelesalesCallPath, 'put');
    if (params) {
      rb.path('name', params.name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzTelesalesCall>;
      })
    );
  }

  /**
   * Update TelesalesCall resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTelesalesCall$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTelesalesCall(
    params: {

    /**
     * The name of the TelesalesCall to update
     */
      name: string;
      body: HmnzTelesalesCall
    },
    context?: HttpContext
  ): Observable<HmnzTelesalesCall> {
    return this.updateTelesalesCall$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzTelesalesCall>): HmnzTelesalesCall => r.body)
    );
  }

}
