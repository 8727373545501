import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, filter, map} from 'rxjs';
import {SERVER_API_URL} from '../../../../app.constants';
import {User} from '../models/user';
import { Login } from '../models/login';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Account } from '../models/account';
import { ChangePassword } from '../models/changePassword';
import { File } from '../models/file';
import { UpdatePassword } from '../models/updatePassword';
import { ResetPassword } from '../models/resetPassword';
import { RequestBuilder } from '../../company-backend/request-builder';
import { StrictHttpResponse } from '../../company-backend/strict-http-response';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private $sessionStorage: SessionStorageService,
    private $localStorage: LocalStorageService) { }

  static readonly RegisterPath = 'api/method/homains_devops.auth.register';

  register(params?: {
    body?: User
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(SERVER_API_URL, AuthService.RegisterPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  static readonly LoginPath = 'api/method/homains_devops.auth.login';

  login(params?: {
    body?: Login
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(SERVER_API_URL, AuthService.LoginPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  static readonly LogoutPath = 'api/method/logout';

  logout(): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(SERVER_API_URL, AuthService.LogoutPath, 'post');
    
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  static readonly UpdateProfilePath = 'api/method/homains_devops.auth.update_profile';

  updateProfile(params?: {
    body?: Account
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(SERVER_API_URL, AuthService.UpdateProfilePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  static readonly ChangePasswordPath = 'api/method/homains_devops.auth.change_password';

  changePassword(params?: {
    body?: ChangePassword
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(SERVER_API_URL, AuthService.ChangePasswordPath, 'post');

    if (params) {
      rb.body(params.body, 'application/json');
    }
    
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  static readonly UploadFilePath = '/api/method/upload_file';

  uploadFile(params?: {
    body?: File
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(SERVER_API_URL, AuthService.UploadFilePath, 'post');

    if (params) {
      rb.body(params.body, 'application/json');
    }
    
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  static readonly UpdatePasswordPath = 'api/method/homains_devops.auth.update_password';

  updatePassword(params?: {
    body?: UpdatePassword
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(SERVER_API_URL, AuthService.UpdatePasswordPath, 'post');

    if (params) {
      rb.body(params.body, 'application/json');
    }
    
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  static readonly ResetPasswordPath = 'api/method/homains_devops.auth.reset_pwd';

  resetPassword(params?: {
    body?: ResetPassword
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(SERVER_API_URL, AuthService.ResetPasswordPath, 'post');

    if (params) {
      rb.body(params.body, 'application/json');
    }
    
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  getSessionToken(): any {
    return this.$sessionStorage.retrieve('authenticationtoken') || null;
  }

  removeLocalSessionToken(): void {
    return this.$localStorage.clear();
  }

}
