/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { HmnzWalletTransaction } from '../models/hmnz-wallet-transaction';
import { WalletTransactionFilters } from '../models/wallet-transaction-filters';

@Injectable({ providedIn: 'root' })
export class WalletTransactionResourceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getWalletTransactions()` */
  static readonly GetWalletTransactionsPath = '/api/resource/WalletTransaction';

  /**
   * Get WalletTransaction resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWalletTransactions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWalletTransactions$Response(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': Array<HmnzWalletTransaction>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, WalletTransactionResourceService.GetWalletTransactionsPath, 'get');
    if (params) {
      rb.query('filters', params.filters, {});
      rb.query('limit_page_length', params.limit_page_length, {});
      rb.query('limit_start', params.limit_start, {});
      rb.query('order_by', params.order_by, {});
      rb.query('or_filters', params.or_filters, {});
      rb.query('fields', params.fields, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': Array<HmnzWalletTransaction>;
        }>;
      })
    );
  }

  /**
   * Get WalletTransaction resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWalletTransactions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWalletTransactions(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<{
'data': Array<HmnzWalletTransaction>;
}> {
    return this.getWalletTransactions$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': Array<HmnzWalletTransaction>;
}>): {
'data': Array<HmnzWalletTransaction>;
} => r.body)
    );
  }

  /** Path part for operation `createWalletTransaction()` */
  static readonly CreateWalletTransactionPath = '/api/resource/WalletTransaction';

  /**
   * Create a new WalletTransaction resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createWalletTransaction()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createWalletTransaction$Response(
    params: {
      body: HmnzWalletTransaction
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': HmnzWalletTransaction;
}>> {
    const rb = new RequestBuilder(this.rootUrl, WalletTransactionResourceService.CreateWalletTransactionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': HmnzWalletTransaction;
        }>;
      })
    );
  }

  /**
   * Create a new WalletTransaction resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createWalletTransaction$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createWalletTransaction(
    params: {
      body: HmnzWalletTransaction
    },
    context?: HttpContext
  ): Observable<{
'data': HmnzWalletTransaction;
}> {
    return this.createWalletTransaction$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': HmnzWalletTransaction;
}>): {
'data': HmnzWalletTransaction;
} => r.body)
    );
  }

  /** Path part for operation `patchWalletTransaction()` */
  static readonly PatchWalletTransactionPath = '/api/resource/WalletTransaction';

  /**
   * Patch WalletTransaction resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchWalletTransaction()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchWalletTransaction$Response(
    params: {
      filters?: WalletTransactionFilters;
      body: HmnzWalletTransaction
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzWalletTransaction>> {
    const rb = new RequestBuilder(this.rootUrl, WalletTransactionResourceService.PatchWalletTransactionPath, 'patch');
    if (params) {
      rb.query('filters', params.filters, {"style":"form"});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzWalletTransaction>;
      })
    );
  }

  /**
   * Patch WalletTransaction resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchWalletTransaction$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchWalletTransaction(
    params: {
      filters?: WalletTransactionFilters;
      body: HmnzWalletTransaction
    },
    context?: HttpContext
  ): Observable<HmnzWalletTransaction> {
    return this.patchWalletTransaction$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzWalletTransaction>): HmnzWalletTransaction => r.body)
    );
  }

  /** Path part for operation `updateWalletTransaction()` */
  static readonly UpdateWalletTransactionPath = '/api/resource/WalletTransaction/{name}';

  /**
   * Update WalletTransaction resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateWalletTransaction()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWalletTransaction$Response(
    params: {

    /**
     * The name of the WalletTransaction to update
     */
      name: string;
      body: HmnzWalletTransaction
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzWalletTransaction>> {
    const rb = new RequestBuilder(this.rootUrl, WalletTransactionResourceService.UpdateWalletTransactionPath, 'put');
    if (params) {
      rb.path('name', params.name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzWalletTransaction>;
      })
    );
  }

  /**
   * Update WalletTransaction resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateWalletTransaction$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWalletTransaction(
    params: {

    /**
     * The name of the WalletTransaction to update
     */
      name: string;
      body: HmnzWalletTransaction
    },
    context?: HttpContext
  ): Observable<HmnzWalletTransaction> {
    return this.updateWalletTransaction$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzWalletTransaction>): HmnzWalletTransaction => r.body)
    );
  }

}
