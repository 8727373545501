/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { Hmnzoplog } from '../models/hmnzoplog';
import { OplogFilters } from '../models/oplog-filters';

@Injectable({ providedIn: 'root' })
export class OplogResourceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createoplog()` */
  static readonly CreateoplogPath = '/api/resource/oplog';

  /**
   * Create a new oplog resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createoplog()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createoplog$Response(
    params: {
      body: Hmnzoplog
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': Hmnzoplog;
}>> {
    const rb = new RequestBuilder(this.rootUrl, OplogResourceService.CreateoplogPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': Hmnzoplog;
        }>;
      })
    );
  }

  /**
   * Create a new oplog resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createoplog$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createoplog(
    params: {
      body: Hmnzoplog
    },
    context?: HttpContext
  ): Observable<{
'data': Hmnzoplog;
}> {
    return this.createoplog$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': Hmnzoplog;
}>): {
'data': Hmnzoplog;
} => r.body)
    );
  }

  /** Path part for operation `patchoplog()` */
  static readonly PatchoplogPath = '/api/resource/oplog';

  /**
   * Patch oplog resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchoplog()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchoplog$Response(
    params: {
      filters?: OplogFilters;
      body: Hmnzoplog
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Hmnzoplog>> {
    const rb = new RequestBuilder(this.rootUrl, OplogResourceService.PatchoplogPath, 'patch');
    if (params) {
      rb.query('filters', params.filters, {"style":"form"});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Hmnzoplog>;
      })
    );
  }

  /**
   * Patch oplog resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchoplog$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchoplog(
    params: {
      filters?: OplogFilters;
      body: Hmnzoplog
    },
    context?: HttpContext
  ): Observable<Hmnzoplog> {
    return this.patchoplog$Response(params, context).pipe(
      map((r: StrictHttpResponse<Hmnzoplog>): Hmnzoplog => r.body)
    );
  }

  /** Path part for operation `updateoplog()` */
  static readonly UpdateoplogPath = '/api/resource/oplog/{name}';

  /**
   * Update oplog resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateoplog()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateoplog$Response(
    params: {

    /**
     * The name of the oplog to update
     */
      name: string;
      body: Hmnzoplog
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Hmnzoplog>> {
    const rb = new RequestBuilder(this.rootUrl, OplogResourceService.UpdateoplogPath, 'put');
    if (params) {
      rb.path('name', params.name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Hmnzoplog>;
      })
    );
  }

  /**
   * Update oplog resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateoplog$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateoplog(
    params: {

    /**
     * The name of the oplog to update
     */
      name: string;
      body: Hmnzoplog
    },
    context?: HttpContext
  ): Observable<Hmnzoplog> {
    return this.updateoplog$Response(params, context).pipe(
      map((r: StrictHttpResponse<Hmnzoplog>): Hmnzoplog => r.body)
    );
  }

}
