import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MessageService} from 'primeng/api';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable()
export class NotificationInterceptor implements HttpInterceptor {
  constructor(private messageService: MessageService) { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (err: any) => {
          this.messageService.clear();

          if (err.error) {

            if (err.error.showAlert) {
              this.messageService.add({ life: 10000, sticky: false, severity: 'warn', summary: err.error.title, detail: err.error.detail });
            } else if (err.error.detail &&  err.error.detail.includes('was not activated')) {
              this.messageService.add({id: 'notActivatedMSG',
               life: 30000, sticky: false, severity: 'error', summary: 'تسجيل الدخول', detail: 'حسابك مش متفعل اتاكد من تفعيل حسابك وجرب تاني' });
            }
          }

        }
      )
    );
  }
}
