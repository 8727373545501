/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { HmnzTelesalesLeadSegment } from '../models/hmnz-telesales-lead-segment';
import { TelesalesLeadSegmentFilters } from '../models/telesales-lead-segment-filters';

@Injectable({ providedIn: 'root' })
export class TelesalesLeadSegmentResourceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTelesalesLeadSegments()` */
  static readonly GetTelesalesLeadSegmentsPath = '/api/resource/TelesalesLeadSegment';

  /**
   * Get TelesalesLeadSegment resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTelesalesLeadSegments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTelesalesLeadSegments$Response(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': Array<HmnzTelesalesLeadSegment>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, TelesalesLeadSegmentResourceService.GetTelesalesLeadSegmentsPath, 'get');
    if (params) {
      rb.query('filters', params.filters, {});
      rb.query('limit_page_length', params.limit_page_length, {});
      rb.query('limit_start', params.limit_start, {});
      rb.query('order_by', params.order_by, {});
      rb.query('or_filters', params.or_filters, {});
      rb.query('fields', params.fields, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': Array<HmnzTelesalesLeadSegment>;
        }>;
      })
    );
  }

  /**
   * Get TelesalesLeadSegment resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTelesalesLeadSegments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTelesalesLeadSegments(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<{
'data': Array<HmnzTelesalesLeadSegment>;
}> {
    return this.getTelesalesLeadSegments$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': Array<HmnzTelesalesLeadSegment>;
}>): {
'data': Array<HmnzTelesalesLeadSegment>;
} => r.body)
    );
  }

  /** Path part for operation `createTelesalesLeadSegment()` */
  static readonly CreateTelesalesLeadSegmentPath = '/api/resource/TelesalesLeadSegment';

  /**
   * Create a new TelesalesLeadSegment resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTelesalesLeadSegment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTelesalesLeadSegment$Response(
    params: {
      body: HmnzTelesalesLeadSegment
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': HmnzTelesalesLeadSegment;
}>> {
    const rb = new RequestBuilder(this.rootUrl, TelesalesLeadSegmentResourceService.CreateTelesalesLeadSegmentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': HmnzTelesalesLeadSegment;
        }>;
      })
    );
  }

  /**
   * Create a new TelesalesLeadSegment resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTelesalesLeadSegment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTelesalesLeadSegment(
    params: {
      body: HmnzTelesalesLeadSegment
    },
    context?: HttpContext
  ): Observable<{
'data': HmnzTelesalesLeadSegment;
}> {
    return this.createTelesalesLeadSegment$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': HmnzTelesalesLeadSegment;
}>): {
'data': HmnzTelesalesLeadSegment;
} => r.body)
    );
  }

  /** Path part for operation `patchTelesalesLeadSegment()` */
  static readonly PatchTelesalesLeadSegmentPath = '/api/resource/TelesalesLeadSegment';

  /**
   * Patch TelesalesLeadSegment resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchTelesalesLeadSegment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTelesalesLeadSegment$Response(
    params: {
      filters?: TelesalesLeadSegmentFilters;
      body: HmnzTelesalesLeadSegment
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzTelesalesLeadSegment>> {
    const rb = new RequestBuilder(this.rootUrl, TelesalesLeadSegmentResourceService.PatchTelesalesLeadSegmentPath, 'patch');
    if (params) {
      rb.query('filters', params.filters, {"style":"form"});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzTelesalesLeadSegment>;
      })
    );
  }

  /**
   * Patch TelesalesLeadSegment resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchTelesalesLeadSegment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTelesalesLeadSegment(
    params: {
      filters?: TelesalesLeadSegmentFilters;
      body: HmnzTelesalesLeadSegment
    },
    context?: HttpContext
  ): Observable<HmnzTelesalesLeadSegment> {
    return this.patchTelesalesLeadSegment$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzTelesalesLeadSegment>): HmnzTelesalesLeadSegment => r.body)
    );
  }

  /** Path part for operation `updateTelesalesLeadSegment()` */
  static readonly UpdateTelesalesLeadSegmentPath = '/api/resource/TelesalesLeadSegment/{name}';

  /**
   * Update TelesalesLeadSegment resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTelesalesLeadSegment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTelesalesLeadSegment$Response(
    params: {

    /**
     * The name of the TelesalesLeadSegment to update
     */
      name: string;
      body: HmnzTelesalesLeadSegment
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzTelesalesLeadSegment>> {
    const rb = new RequestBuilder(this.rootUrl, TelesalesLeadSegmentResourceService.UpdateTelesalesLeadSegmentPath, 'put');
    if (params) {
      rb.path('name', params.name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzTelesalesLeadSegment>;
      })
    );
  }

  /**
   * Update TelesalesLeadSegment resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTelesalesLeadSegment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTelesalesLeadSegment(
    params: {

    /**
     * The name of the TelesalesLeadSegment to update
     */
      name: string;
      body: HmnzTelesalesLeadSegment
    },
    context?: HttpContext
  ): Observable<HmnzTelesalesLeadSegment> {
    return this.updateTelesalesLeadSegment$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzTelesalesLeadSegment>): HmnzTelesalesLeadSegment => r.body)
    );
  }

  /** Path part for operation `deleteTelesalesLeadSegment()` */
  static readonly DeleteTelesalesLeadSegmentPath = '/api/resource/TelesalesLeadSegment/{name}';

  /**
   * Delete TelesalesLeadSegment resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTelesalesLeadSegment()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTelesalesLeadSegment$Response(
    params: {

    /**
     * The name of the TelesalesLeadSegment to delete
     */
      name: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, TelesalesLeadSegmentResourceService.DeleteTelesalesLeadSegmentPath, 'delete');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Delete TelesalesLeadSegment resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTelesalesLeadSegment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTelesalesLeadSegment(
    params: {

    /**
     * The name of the TelesalesLeadSegment to delete
     */
      name: string;
    },
    context?: HttpContext
  ): Observable<any> {
    return this.deleteTelesalesLeadSegment$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
