import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CookieModule } from 'ngx-cookie';

@NgModule({
  imports: [
    NgbModule,
    CookieModule.forRoot(),
  ]
})
export class ResellerSharedLibsModule {
  static forRoot() {
    return {
      ngModule: ResellerSharedLibsModule
    };
  }
}
