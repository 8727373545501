/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { HmnzTelesalesJobRequest } from '../models/hmnz-telesales-job-request';
import { TelesalesJobRequestFilters } from '../models/telesales-job-request-filters';

@Injectable({ providedIn: 'root' })
export class TelesalesJobRequestResourceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTelesalesJobRequests()` */
  static readonly GetTelesalesJobRequestsPath = '/api/resource/TelesalesJobRequest';

  /**
   * Get TelesalesJobRequest resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTelesalesJobRequests()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTelesalesJobRequests$Response(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': Array<HmnzTelesalesJobRequest>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, TelesalesJobRequestResourceService.GetTelesalesJobRequestsPath, 'get');
    if (params) {
      rb.query('filters', params.filters, {});
      rb.query('limit_page_length', params.limit_page_length, {});
      rb.query('limit_start', params.limit_start, {});
      rb.query('order_by', params.order_by, {});
      rb.query('or_filters', params.or_filters, {});
      rb.query('fields', params.fields, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': Array<HmnzTelesalesJobRequest>;
        }>;
      })
    );
  }

  /**
   * Get TelesalesJobRequest resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTelesalesJobRequests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTelesalesJobRequests(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<{
'data': Array<HmnzTelesalesJobRequest>;
}> {
    return this.getTelesalesJobRequests$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': Array<HmnzTelesalesJobRequest>;
}>): {
'data': Array<HmnzTelesalesJobRequest>;
} => r.body)
    );
  }

  /** Path part for operation `createTelesalesJobRequest()` */
  static readonly CreateTelesalesJobRequestPath = '/api/resource/TelesalesJobRequest';

  /**
   * Create a new TelesalesJobRequest resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTelesalesJobRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTelesalesJobRequest$Response(
    params: {
      body: HmnzTelesalesJobRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': HmnzTelesalesJobRequest;
}>> {
    const rb = new RequestBuilder(this.rootUrl, TelesalesJobRequestResourceService.CreateTelesalesJobRequestPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': HmnzTelesalesJobRequest;
        }>;
      })
    );
  }

  /**
   * Create a new TelesalesJobRequest resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTelesalesJobRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTelesalesJobRequest(
    params: {
      body: HmnzTelesalesJobRequest
    },
    context?: HttpContext
  ): Observable<{
'data': HmnzTelesalesJobRequest;
}> {
    return this.createTelesalesJobRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': HmnzTelesalesJobRequest;
}>): {
'data': HmnzTelesalesJobRequest;
} => r.body)
    );
  }

  /** Path part for operation `patchTelesalesJobRequest()` */
  static readonly PatchTelesalesJobRequestPath = '/api/resource/TelesalesJobRequest';

  /**
   * Patch TelesalesJobRequest resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchTelesalesJobRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTelesalesJobRequest$Response(
    params: {
      filters?: TelesalesJobRequestFilters;
      body: HmnzTelesalesJobRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzTelesalesJobRequest>> {
    const rb = new RequestBuilder(this.rootUrl, TelesalesJobRequestResourceService.PatchTelesalesJobRequestPath, 'patch');
    if (params) {
      rb.query('filters', params.filters, {"style":"form"});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzTelesalesJobRequest>;
      })
    );
  }

  /**
   * Patch TelesalesJobRequest resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchTelesalesJobRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTelesalesJobRequest(
    params: {
      filters?: TelesalesJobRequestFilters;
      body: HmnzTelesalesJobRequest
    },
    context?: HttpContext
  ): Observable<HmnzTelesalesJobRequest> {
    return this.patchTelesalesJobRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzTelesalesJobRequest>): HmnzTelesalesJobRequest => r.body)
    );
  }

  /** Path part for operation `updateTelesalesJobRequest()` */
  static readonly UpdateTelesalesJobRequestPath = '/api/resource/TelesalesJobRequest/{name}';

  /**
   * Update TelesalesJobRequest resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTelesalesJobRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTelesalesJobRequest$Response(
    params: {

    /**
     * The name of the TelesalesJobRequest to update
     */
      name: string;
      body: HmnzTelesalesJobRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzTelesalesJobRequest>> {
    const rb = new RequestBuilder(this.rootUrl, TelesalesJobRequestResourceService.UpdateTelesalesJobRequestPath, 'put');
    if (params) {
      rb.path('name', params.name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzTelesalesJobRequest>;
      })
    );
  }

  /**
   * Update TelesalesJobRequest resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTelesalesJobRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTelesalesJobRequest(
    params: {

    /**
     * The name of the TelesalesJobRequest to update
     */
      name: string;
      body: HmnzTelesalesJobRequest
    },
    context?: HttpContext
  ): Observable<HmnzTelesalesJobRequest> {
    return this.updateTelesalesJobRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzTelesalesJobRequest>): HmnzTelesalesJobRequest => r.body)
    );
  }

}
