import {Injectable} from '@angular/core';
import {Observable, Observer, Subscription} from 'rxjs';
import {filter, map, share} from 'rxjs/operators';

/**
 * An utility class to manage RX events
 */
@Injectable({
  providedIn: 'root'
})
export class EventManager {
  observable: Observable<string>;
  observer: Observer<string>;

  constructor() {
    this.observable = new Observable((observer: Observer<string>) => {
      this.observer = observer;
    }).pipe(share());
  }

  /**
   * Method to broadcast the event to observer
   */
  broadcast(event: string): void {
    if (this.observer) {
      this.observer.next(event);
    }
  }

  /**
   * Method to subscribe to an event with callback
   */
  subscribe(eventName: string, callback: any): Subscription {
    return this.observable
    .pipe(
      filter((event: string) => {
          return event === eventName;
      }),
      map((event: string) => {
        if (typeof event !== 'string') {
          // when releasing generator-jhipster v7 then current return will be changed to
          // (to avoid redundant code response.content in JhiEventManager.subscribe callbacks):
          // return event.content;
          return event;
        }
      })
    )
    .subscribe(callback);
  }

  /**
   * Method to unsubscribe the subscription
   */
  destroy(subscriber: Subscription): void {
    subscriber.unsubscribe();
  }

  broadcastUserSettingUpdate(): void {
    this.broadcast(EventUpdateType.settings.toString());
  }
  broadcastEvent(event: EventUpdateType): void {
    this.broadcast(event.toString());
  }
  subscripeEvent(event: EventUpdateType, callBack?: any): Subscription {
    return this.subscribe(event.toString(), callBack || null);
  }

}
export enum EventUpdateType {
  settings,
  requests,
  childerns,
  droos,
  schedule,
  darsScheduleList
}
