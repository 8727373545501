import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { SERVER_NEW_API_URL } from '../../../../../app.constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!request || !request.url || (/^http/.test(request.url) && !(SERVER_NEW_API_URL && request.url.startsWith(SERVER_NEW_API_URL)))) {
      return next.handle(request);
    }

    return next.handle(request);
  }
}
