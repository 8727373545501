import { DOCUMENT } from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {Observable, Observer} from 'rxjs';

declare const $: any;
@Injectable()
export class ScriptLoader {

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {}

  scriptsX: string[] = [];
  private scripts: ScriptModel[] = [];

  addScript(name: string): void {
    this.scriptsX.push(name);
  }
  getScripts(): string[] {
    return this.scriptsX;
  }
  empty(): void {
    this.scriptsX = [];
  }

  public isLoaded(script: ScriptModel): boolean {
    const existingScript = this.scripts.find(s => s.name === script.name);
    if (existingScript) {
      return existingScript.loaded;
    } else {
      return false;
    }
  }
  public load(script: ScriptModel): Observable<ScriptModel> {
    return new Observable<ScriptModel>((observer: Observer<ScriptModel>) => {
      const existingScript = this.scripts.find(s => s.name === script.name);
      // const scriptTag = document.getElementById('script-' + script.name);
      // Complete if already loaded
      if (existingScript && existingScript.loaded) {
        observer.next(existingScript);
        observer.complete();
      } else {
        // Add the script
        this.scripts = [...this.scripts, script];

        // Load the script
        const scriptElement = this.document.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.src = script.src;
        scriptElement.id = 'script-' + script.name;

        scriptElement.onload = () => {
          script.loaded = true;
          observer.next(script);
          observer.complete();
        };

        scriptElement.onerror = (error: any) => {
          observer.error('Couldn\'t load script ' + script.src + '  ' + error);
        };

        this.document.getElementsByTagName('body')[0].appendChild(scriptElement);
      }
    });
  }
}
export interface ScriptModel {
  name: string;
  src: string;
  loaded: boolean;
}
