// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  backend: '',
  newBackend: '',
  stripeKey: 'pk_test_51K9tIuG4ANglRi0QyAJKEblro7uh2Mm8jlVcoHOVfsLtqu5fJv2pLmfWr0CWRoyZBnbkAfOyKY2L1qRsVOUlXXe700J6nN8DKy'
};