import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AuthServerProvider} from './auth-token.service';

@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {
  constructor(private authServerProvider: AuthServerProvider) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if ((err.status === 401 || err.status === 403) && err.url && !err.url.includes('api/method/homains_devops.auth.account')) {
              this.authServerProvider.logout();
            }

            if (err.status === 504 && err.url && err.url.includes('api/method/homains_devops.auth.account')) {
              this.authServerProvider.logout();
            }
          }
        }
      )
    );
  }
}
