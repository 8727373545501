import {Injectable} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HmnzTsRevenue } from '../../sales-backend/models';
import { TsRevenueResourceService } from '../../sales-backend/services';

@Injectable({ providedIn: 'root' })
export class TelesalesRevenueService {

  telesalesRevenue = new BehaviorSubject<HmnzTsRevenue>(null);

  constructor(
    private tsRevenueResourceService: TsRevenueResourceService
  ) {}

  setTelesalesRevenue(revenue): void {
    this.telesalesRevenue.next(revenue);
  }

  getTelesalesRevenue(): Observable<HmnzTsRevenue> {
    return this.telesalesRevenue.asObservable();
  }

  refreshRevenueData() {
    this.tsRevenueResourceService.getTsRevenues({
      fields: '["amount", "currency", "name"]',
      filters: null,
      or_filters: null
    }).subscribe({
      next: (res) => {
        this.telesalesRevenue.next(res.data[0]);
      }
    });
  }

  clearTelesalesRevenue() {
    this.telesalesRevenue.next(null);
  }
}
