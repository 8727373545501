/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { HmnzSalesLead } from '../models/hmnz-sales-lead';
import { SalesLeadFilters } from '../models/sales-lead-filters';

@Injectable({ providedIn: 'root' })
export class SalesLeadResourceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getSalesLeads()` */
  static readonly GetSalesLeadsPath = '/api/resource/SalesLead';

  /**
   * Get SalesLead resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSalesLeads()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSalesLeads$Response(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': Array<HmnzSalesLead>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, SalesLeadResourceService.GetSalesLeadsPath, 'get');
    if (params) {
      rb.query('filters', params.filters, {});
      rb.query('limit_page_length', params.limit_page_length, {});
      rb.query('limit_start', params.limit_start, {});
      rb.query('order_by', params.order_by, {});
      rb.query('or_filters', params.or_filters, {});
      rb.query('fields', params.fields, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': Array<HmnzSalesLead>;
        }>;
      })
    );
  }

  /**
   * Get SalesLead resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSalesLeads$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSalesLeads(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<{
'data': Array<HmnzSalesLead>;
}> {
    return this.getSalesLeads$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': Array<HmnzSalesLead>;
}>): {
'data': Array<HmnzSalesLead>;
} => r.body)
    );
  }

  /** Path part for operation `createSalesLead()` */
  static readonly CreateSalesLeadPath = '/api/resource/SalesLead';

  /**
   * Create a new SalesLead resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSalesLead()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSalesLead$Response(
    params: {
      body: HmnzSalesLead
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': HmnzSalesLead;
}>> {
    const rb = new RequestBuilder(this.rootUrl, SalesLeadResourceService.CreateSalesLeadPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': HmnzSalesLead;
        }>;
      })
    );
  }

  /**
   * Create a new SalesLead resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createSalesLead$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSalesLead(
    params: {
      body: HmnzSalesLead
    },
    context?: HttpContext
  ): Observable<{
'data': HmnzSalesLead;
}> {
    return this.createSalesLead$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': HmnzSalesLead;
}>): {
'data': HmnzSalesLead;
} => r.body)
    );
  }

  /** Path part for operation `patchSalesLead()` */
  static readonly PatchSalesLeadPath = '/api/resource/SalesLead';

  /**
   * Patch SalesLead resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchSalesLead()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchSalesLead$Response(
    params: {
      filters?: SalesLeadFilters;
      body: HmnzSalesLead
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzSalesLead>> {
    const rb = new RequestBuilder(this.rootUrl, SalesLeadResourceService.PatchSalesLeadPath, 'patch');
    if (params) {
      rb.query('filters', params.filters, {"style":"form"});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzSalesLead>;
      })
    );
  }

  /**
   * Patch SalesLead resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchSalesLead$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchSalesLead(
    params: {
      filters?: SalesLeadFilters;
      body: HmnzSalesLead
    },
    context?: HttpContext
  ): Observable<HmnzSalesLead> {
    return this.patchSalesLead$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzSalesLead>): HmnzSalesLead => r.body)
    );
  }

  /** Path part for operation `updateSalesLead()` */
  static readonly UpdateSalesLeadPath = '/api/resource/SalesLead/{name}';

  /**
   * Update SalesLead resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSalesLead()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSalesLead$Response(
    params: {

    /**
     * The name of the SalesLead to update
     */
      name: string;
      body: HmnzSalesLead
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzSalesLead>> {
    const rb = new RequestBuilder(this.rootUrl, SalesLeadResourceService.UpdateSalesLeadPath, 'put');
    if (params) {
      rb.path('name', params.name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzSalesLead>;
      })
    );
  }

  /**
   * Update SalesLead resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateSalesLead$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSalesLead(
    params: {

    /**
     * The name of the SalesLead to update
     */
      name: string;
      body: HmnzSalesLead
    },
    context?: HttpContext
  ): Observable<HmnzSalesLead> {
    return this.updateSalesLead$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzSalesLead>): HmnzSalesLead => r.body)
    );
  }

  /** Path part for operation `deleteSalesLead()` */
  static readonly DeleteSalesLeadPath = '/api/resource/SalesLead/{name}';

  /**
   * Delete SalesLead resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSalesLead()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSalesLead$Response(
    params: {

    /**
     * The name of the SalesLead to delete
     */
      name: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, SalesLeadResourceService.DeleteSalesLeadPath, 'delete');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Delete SalesLead resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteSalesLead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSalesLead(
    params: {

    /**
     * The name of the SalesLead to delete
     */
      name: string;
    },
    context?: HttpContext
  ): Observable<any> {
    return this.deleteSalesLead$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
