/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { HmnzTsProfile } from '../models/hmnz-ts-profile';
import { TsProfileFilters } from '../models/ts-profile-filters';

@Injectable({ providedIn: 'root' })
export class TsProfileResourceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTsProfiles()` */
  static readonly GetTsProfilesPath = '/api/resource/TSProfile';

  /**
   * Get TSProfile resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTsProfiles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTsProfiles$Response(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': Array<HmnzTsProfile>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, TsProfileResourceService.GetTsProfilesPath, 'get');
    if (params) {
      rb.query('filters', params.filters, {});
      rb.query('limit_page_length', params.limit_page_length, {});
      rb.query('limit_start', params.limit_start, {});
      rb.query('order_by', params.order_by, {});
      rb.query('or_filters', params.or_filters, {});
      rb.query('fields', params.fields, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': Array<HmnzTsProfile>;
        }>;
      })
    );
  }

  /**
   * Get TSProfile resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTsProfiles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTsProfiles(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<{
'data': Array<HmnzTsProfile>;
}> {
    return this.getTsProfiles$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': Array<HmnzTsProfile>;
}>): {
'data': Array<HmnzTsProfile>;
} => r.body)
    );
  }

  /** Path part for operation `patchTsProfile()` */
  static readonly PatchTsProfilePath = '/api/resource/TSProfile';

  /**
   * Patch TSProfile resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchTsProfile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTsProfile$Response(
    params: {
      filters?: TsProfileFilters;
      body: HmnzTsProfile
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzTsProfile>> {
    const rb = new RequestBuilder(this.rootUrl, TsProfileResourceService.PatchTsProfilePath, 'patch');
    if (params) {
      rb.query('filters', params.filters, {"style":"form"});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzTsProfile>;
      })
    );
  }

  /**
   * Patch TSProfile resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchTsProfile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTsProfile(
    params: {
      filters?: TsProfileFilters;
      body: HmnzTsProfile
    },
    context?: HttpContext
  ): Observable<HmnzTsProfile> {
    return this.patchTsProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzTsProfile>): HmnzTsProfile => r.body)
    );
  }

  /** Path part for operation `updateTsProfile()` */
  static readonly UpdateTsProfilePath = '/api/resource/TSProfile/{name}';

  /**
   * Update TSProfile resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTsProfile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTsProfile$Response(
    params: {

    /**
     * The name of the TSProfile to update
     */
      name: string;
      body: HmnzTsProfile
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzTsProfile>> {
    const rb = new RequestBuilder(this.rootUrl, TsProfileResourceService.UpdateTsProfilePath, 'put');
    if (params) {
      rb.path('name', params.name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzTsProfile>;
      })
    );
  }

  /**
   * Update TSProfile resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTsProfile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTsProfile(
    params: {

    /**
     * The name of the TSProfile to update
     */
      name: string;
      body: HmnzTsProfile
    },
    context?: HttpContext
  ): Observable<HmnzTsProfile> {
    return this.updateTsProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzTsProfile>): HmnzTsProfile => r.body)
    );
  }

}
