// These constants are injected via webpack environment variables.
// You can add more variables in webpack.common.js or in profile specific webpack.<dev|prod>.js files.
// If you change the values in the webpack config files, you need to re run webpack to update the application

import {environment} from '../environments/environment';

export const DEBUG_INFO_ENABLED = false;
export const SERVER_API_URL = environment.backend;
export const SERVER_NEW_API_URL = environment.newBackend;
export const DATATABLES_LANGUAGE = {
                'zeroRecords': '',
                'sProcessing': 'ثانية بس بجيب الداتا',
                'sLengthMenu': 'إعرض &nbsp;  _MENU_ &nbsp; نتايج ',
                'sZeroRecords': '',
                'sInfo': 'إظهار _START_ إلى _END_ من أصل _TOTAL_ صف',
                'sInfoEmpty': 'يعرض 0 إلى 0 من أصل 0 صف',
                'sInfoFiltered': '(من مجموع _MAX_ صفوف)',
                'sInfoPostFix': '',
                'sSearch': 'دور:',
                'sUrl': '',
                'oPaginate': {
                    'sFirst': '<<',
                    'sPrevious': '<',
                    'sNext': '>',
                    'sLast': '>>'
                }
            };

export const ProjectName = {
    ar : 'هنبيع',
    en : 'Hanbee3'
};

export const RegisterAs = {
    COMPANY : 'Company',
    SALES_PERSON : 'Salesperson',
    CALL_CENTER : 'CallCenter'
};

export const ProductStatus = {
    OFFLINE: 'OFFLINE',
    ONLINE: 'ONLINE',
    PENDING_APPROVAL: 'PENDING_APPROVAL'
};

export const GUILocalization = {
    'translation': {
        "sourceEmpty": "مفيش داتا",
        "pagingItemsPerPage": "الداتا المعروضه للصفحة :   ",
        "pagingOf": "من",
        "pagingNextPage": "اللي بعده",
        "pagingPrevPage": "ارجع",
        "pagingNoItems": "مفيش داتا",
        "infoPanelShowing": "عرض",
        "infoPanelItems": "داتا",
        "infoPanelOutOf": "من",
        "infoPanelThemeMangerTooltipText": "اعدادات الثيم",
        "infoPanelColumnManagerTooltipText": "اعدادات العمود",
        "infoPanelInfoTooltipText": "معلومات",
        "themeManagerModalTitle": "اعدادات الثيم",
        "themeManagerModalTheme": "الثيم:",
        "themeManagerModalRowColoring": "تلوين الصف:",
        "themeManagerModalVerticalGrid": "عرض بشكل عمودى",
        "themeManagerModalHorizontalGrid": "عرض بشكل افقى",
        "columnManagerModalTitle": "اعدادات العمود",
        "headerMenuMainTab": "المنيو",
        "headerMenuMainTabColumnSort": "ترتيب الاعمدة",
        "headerMenuMainTabHideColumn": "اخفى العمود",
        "headerMenuMainTabHighlightColumn": "تحديد ك مهم",
        "headerMenuMainTabMoveLeft": "حرك يمين",
        "headerMenuMainTabMoveRight": "حرك شمال",
        "headerMenuMainTabColumnSortAscending": "ترتيب تصاعدى",
        "headerMenuMainTabColumnSortDescending": "ترتيب تنازاى",
        "headerMenuMainTabColumnSortNone": "إلغى",
        "headerMenuFilterTab": "فيلتر",
        "headerMenuColumnsTab": "اعمده",
        "summariesCount": "عداد",
        "summariesDist": "توزيع",
        "summariesSum": "مجموع",
        "summariesAvg": "متوسط",
        "summariesMin": "الأقل",
        "summariesMax": "الأكتر",
        "summariesMed": "المتوسط",
        "summariesTruthy": "Truthy",
        "summariesFalsy": "Falsy",
        "summariesDistinctValuesTooltip": "Distinct values",
        "summariesAverageTooltip": "متوسط",
        "summariesMinTooltip": "الأقل",
        "summariesMaxTooltip": "ألاكتر",
        "summariesMedTooltip": "المتوسط",
        "summariesCountTooltip": "الداتا المعروضة"
    }
}

export const Roles = {
    RESELLER: 'hmnz_r_telesales_reseller'
}


export const Currencies = [
    {
      id: 'EGP',
      title: 'جنيه'
    }
    // {
    //   id: 'USD',
    //   title: 'دولار'
    // },
    // {
    //   id: 'EUR',
    //   title: 'يورو'
    // }
];
export const CallsReasons = [
  {
    name: 'Sales',
    title: 'بيع'
  },
  {
    name: 'Leads',
    title: 'تقييم'
  },
  {
    name: 'other',
    title: 'أخرى'
  }
];
export const LeadsSources = [
  {
    name: 'MNO',
    title: 'أرقامى بس'
  },
  {
    name: 'MN_TSN',
    title: 'أرقامى وأرقام التيلي سيلز'
  },
  {
    name: 'TSNO',
    title: 'أرقام التيلي سيلز بس'
  }
];
export const SalesLeadsSources = [
  {
    name: 'MNO',
    title: 'أرقام الشركة بس'
  },
  {
    name: 'MN_TSN',
    title: 'أرقامى وأرقام الشركه'
  },
  {
    name: 'TSNO',
    title: 'أرقامى بس'
  }
];

export const Colors = {
  danger: '#ef5350',
  success: '#26da80',
  warning: '#ECAB3A',
  primary: '#2196f3',
  info: '#17a2b8',
  purple: '#6f42c1',
  secondary: '#485A75',
  darkRed: '#993938'
}
