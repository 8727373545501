/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';


@Injectable({ providedIn: 'root' })
export class CallCenterService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `telesalesCallCenterAddEmployee()` */
  static readonly TelesalesCallCenterAddEmployeePath = '/api/method/telesales.call_center.add_employee';

  /**
   * Invoke the telesales.call_center.add_employee method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesCallCenterAddEmployee()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCallCenterAddEmployee$Response(
    params: {

    /**
     * First Name
     */
      first_name: string;

    /**
     * Last Name
     */
      last_name: string;

    /**
     * Email
     */
      email: string;

    /**
     * Role
     */
      reg_as: string;

    /**
     * Phone number
     */
      phone_number: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, CallCenterService.TelesalesCallCenterAddEmployeePath, 'post');
    if (params) {
      rb.query('first_name', params.first_name, {});
      rb.query('last_name', params.last_name, {});
      rb.query('email', params.email, {});
      rb.query('reg_as', params.reg_as, {});
      rb.query('phone_number', params.phone_number, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.call_center.add_employee method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesCallCenterAddEmployee$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCallCenterAddEmployee(
    params: {

    /**
     * First Name
     */
      first_name: string;

    /**
     * Last Name
     */
      last_name: string;

    /**
     * Email
     */
      email: string;

    /**
     * Role
     */
      reg_as: string;

    /**
     * Phone number
     */
      phone_number: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesCallCenterAddEmployee$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
