/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { HmnzHmnzUserPrefs } from '../models/hmnz-hmnz-user-prefs';
import { HmnzUserPrefsFilters } from '../models/hmnz-user-prefs-filters';

@Injectable({ providedIn: 'root' })
export class HmnzUserPrefsResourceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getHmnzUserPrefss()` */
  static readonly GetHmnzUserPrefssPath = '/api/resource/HmnzUserPrefs';

  /**
   * Get HmnzUserPrefs resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHmnzUserPrefss()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHmnzUserPrefss$Response(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': Array<HmnzHmnzUserPrefs>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, HmnzUserPrefsResourceService.GetHmnzUserPrefssPath, 'get');
    if (params) {
      rb.query('filters', params.filters, {});
      rb.query('limit_page_length', params.limit_page_length, {});
      rb.query('limit_start', params.limit_start, {});
      rb.query('order_by', params.order_by, {});
      rb.query('or_filters', params.or_filters, {});
      rb.query('fields', params.fields, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': Array<HmnzHmnzUserPrefs>;
        }>;
      })
    );
  }

  /**
   * Get HmnzUserPrefs resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHmnzUserPrefss$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHmnzUserPrefss(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<{
'data': Array<HmnzHmnzUserPrefs>;
}> {
    return this.getHmnzUserPrefss$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': Array<HmnzHmnzUserPrefs>;
}>): {
'data': Array<HmnzHmnzUserPrefs>;
} => r.body)
    );
  }

  /** Path part for operation `createHmnzUserPrefs()` */
  static readonly CreateHmnzUserPrefsPath = '/api/resource/HmnzUserPrefs';

  /**
   * Create a new HmnzUserPrefs resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createHmnzUserPrefs()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createHmnzUserPrefs$Response(
    params: {
      body: HmnzHmnzUserPrefs
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': HmnzHmnzUserPrefs;
}>> {
    const rb = new RequestBuilder(this.rootUrl, HmnzUserPrefsResourceService.CreateHmnzUserPrefsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': HmnzHmnzUserPrefs;
        }>;
      })
    );
  }

  /**
   * Create a new HmnzUserPrefs resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createHmnzUserPrefs$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createHmnzUserPrefs(
    params: {
      body: HmnzHmnzUserPrefs
    },
    context?: HttpContext
  ): Observable<{
'data': HmnzHmnzUserPrefs;
}> {
    return this.createHmnzUserPrefs$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': HmnzHmnzUserPrefs;
}>): {
'data': HmnzHmnzUserPrefs;
} => r.body)
    );
  }

  /** Path part for operation `patchHmnzUserPrefs()` */
  static readonly PatchHmnzUserPrefsPath = '/api/resource/HmnzUserPrefs';

  /**
   * Patch HmnzUserPrefs resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchHmnzUserPrefs()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchHmnzUserPrefs$Response(
    params: {
      filters?: HmnzUserPrefsFilters;
      body: HmnzHmnzUserPrefs
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzHmnzUserPrefs>> {
    const rb = new RequestBuilder(this.rootUrl, HmnzUserPrefsResourceService.PatchHmnzUserPrefsPath, 'patch');
    if (params) {
      rb.query('filters', params.filters, {"style":"form"});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzHmnzUserPrefs>;
      })
    );
  }

  /**
   * Patch HmnzUserPrefs resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchHmnzUserPrefs$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchHmnzUserPrefs(
    params: {
      filters?: HmnzUserPrefsFilters;
      body: HmnzHmnzUserPrefs
    },
    context?: HttpContext
  ): Observable<HmnzHmnzUserPrefs> {
    return this.patchHmnzUserPrefs$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzHmnzUserPrefs>): HmnzHmnzUserPrefs => r.body)
    );
  }

  /** Path part for operation `updateHmnzUserPrefs()` */
  static readonly UpdateHmnzUserPrefsPath = '/api/resource/HmnzUserPrefs/{name}';

  /**
   * Update HmnzUserPrefs resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateHmnzUserPrefs()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateHmnzUserPrefs$Response(
    params: {

    /**
     * The name of the HmnzUserPrefs to update
     */
      name: string;
      body: HmnzHmnzUserPrefs
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzHmnzUserPrefs>> {
    const rb = new RequestBuilder(this.rootUrl, HmnzUserPrefsResourceService.UpdateHmnzUserPrefsPath, 'put');
    if (params) {
      rb.path('name', params.name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzHmnzUserPrefs>;
      })
    );
  }

  /**
   * Update HmnzUserPrefs resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateHmnzUserPrefs$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateHmnzUserPrefs(
    params: {

    /**
     * The name of the HmnzUserPrefs to update
     */
      name: string;
      body: HmnzHmnzUserPrefs
    },
    context?: HttpContext
  ): Observable<HmnzHmnzUserPrefs> {
    return this.updateHmnzUserPrefs$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzHmnzUserPrefs>): HmnzHmnzUserPrefs => r.body)
    );
  }

}
