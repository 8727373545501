import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const routes: Routes = [
    {
        path: 'redirect',
        loadChildren: () => import('./hanbee3-admin/pages/redirect/redirect.module').then(m => m.RedirectModule),
        data: {
            title: {
                en: 'Wait',
                ar: 'استنى شوية',
            }
        }
    },
    {
        path: 'campaigns',
        loadChildren: () => import('./market-place/core/campaigns/campaigns.module').then(m => m.CampaignsModule)
    },
    {
        path: '',
        loadChildren: () => import('./market-place/core/components/components.module').then((m) => m.ComponentsModule)
    },
    {
        path: '',
        loadChildren: () => import('./hanbee3-admin/pages/pages.module').then(m => m.PagesModule)
    },
    {
      path: '**',
      redirectTo: '/home',
      pathMatch: 'full'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, 
            {
                scrollPositionRestoration: 'enabled',
                initialNavigation: 'enabledBlocking'
            }
        )
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
