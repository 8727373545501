import {Inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LocalStorageService} from 'ngx-webstorage';
import {Subject} from 'rxjs';
import {EventService} from './event.service';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  public languages: string[] = ['en', 'ar'];
  public langIndex = '1';
  lang;
  public currentLang = new Subject();

  constructor(
    public translate: TranslateService,
    private localStorageService: LocalStorageService,
    private eventService: EventService,
    @Inject(DOCUMENT) private document: Document) {
    let browserLang;
    this.translate.addLangs(this.languages);
    if (this.localStorageService.retrieve('lang')) {
      browserLang = this.localStorageService.retrieve('lang');
    } else {
      // browserLang = window.navigator.languages[2];
    }
    if (browserLang === 'ar') {
      this.langIndex = '1';
      document.getElementById('theme-opt')?.setAttribute('href', '/assets/scss/rtl/style-rtl.scss');
    }
    translate.use(browserLang?.match(/en|ar/) ? browserLang : 'ar');
  }

  public setLanguage(lang): void {
    this.translate.use(lang);
    this.currentLang.next(lang);
    this.eventService.broadcast('lang', {lang});
    this.localStorageService.store('lang', lang);
    if (lang === 'ar') {
      this.langIndex = '1';
      this.document.getElementById('theme-opt')?.setAttribute('href', './assets/scss/rtl/style-rtl.scss');
    } else {
      this.langIndex = '0';
      this.document.getElementById('theme-opt')?.setAttribute('href', './assets/scss/style-ltr.scss');
    }
  }

  public getLanguageIndex(): any {
    return this.langIndex;
  }

  public getCurrentLanguage(): any {
    if (this.localStorageService.retrieve('lang')) {
      this.lang = this.localStorageService.retrieve('lang');
    } else {
      this.localStorageService.store('lang', this.languages[1]);
      this.lang = this.localStorageService.retrieve('lang');
    }
    this.currentLang.next(this.lang);
    return this.currentLang.asObservable();
  }
}
