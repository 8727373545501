/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { HmnzTsCallRec } from '../models/hmnz-ts-call-rec';
import { TsCallRecFilters } from '../models/ts-call-rec-filters';

@Injectable({ providedIn: 'root' })
export class TsCallRecResourceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createTsCallRec()` */
  static readonly CreateTsCallRecPath = '/api/resource/TSCallRec';

  /**
   * Create a new TSCallRec resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTsCallRec()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTsCallRec$Response(
    params: {
      body: HmnzTsCallRec
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': HmnzTsCallRec;
}>> {
    const rb = new RequestBuilder(this.rootUrl, TsCallRecResourceService.CreateTsCallRecPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': HmnzTsCallRec;
        }>;
      })
    );
  }

  /**
   * Create a new TSCallRec resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTsCallRec$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTsCallRec(
    params: {
      body: HmnzTsCallRec
    },
    context?: HttpContext
  ): Observable<{
'data': HmnzTsCallRec;
}> {
    return this.createTsCallRec$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': HmnzTsCallRec;
}>): {
'data': HmnzTsCallRec;
} => r.body)
    );
  }

  /** Path part for operation `patchTsCallRec()` */
  static readonly PatchTsCallRecPath = '/api/resource/TSCallRec';

  /**
   * Patch TSCallRec resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchTsCallRec()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTsCallRec$Response(
    params: {
      filters?: TsCallRecFilters;
      body: HmnzTsCallRec
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzTsCallRec>> {
    const rb = new RequestBuilder(this.rootUrl, TsCallRecResourceService.PatchTsCallRecPath, 'patch');
    if (params) {
      rb.query('filters', params.filters, {"style":"form"});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzTsCallRec>;
      })
    );
  }

  /**
   * Patch TSCallRec resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchTsCallRec$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTsCallRec(
    params: {
      filters?: TsCallRecFilters;
      body: HmnzTsCallRec
    },
    context?: HttpContext
  ): Observable<HmnzTsCallRec> {
    return this.patchTsCallRec$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzTsCallRec>): HmnzTsCallRec => r.body)
    );
  }

  /** Path part for operation `updateTsCallRec()` */
  static readonly UpdateTsCallRecPath = '/api/resource/TSCallRec/{name}';

  /**
   * Update TSCallRec resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTsCallRec()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTsCallRec$Response(
    params: {

    /**
     * The name of the TSCallRec to update
     */
      name: string;
      body: HmnzTsCallRec
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzTsCallRec>> {
    const rb = new RequestBuilder(this.rootUrl, TsCallRecResourceService.UpdateTsCallRecPath, 'put');
    if (params) {
      rb.path('name', params.name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzTsCallRec>;
      })
    );
  }

  /**
   * Update TSCallRec resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTsCallRec$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTsCallRec(
    params: {

    /**
     * The name of the TSCallRec to update
     */
      name: string;
      body: HmnzTsCallRec
    },
    context?: HttpContext
  ): Observable<HmnzTsCallRec> {
    return this.updateTsCallRec$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzTsCallRec>): HmnzTsCallRec => r.body)
    );
  }

}
